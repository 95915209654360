<template>
	<el-dialog
		v-model="visible"
		width="700px"
		class="container"
		title="司机报名跟进"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading">
			<el-input
				type="textarea"
				v-model="formData.content"
				placeholder="在此填写跟进内容"
				:autosize="{ minRows: 4 }"
				:maxlength="100"
				show-word-limit
			/>
			<div class="button-list">
				<el-button type="primary" @click="onSubmit" :disabled="disabled">提交记录</el-button>
				<el-button @click="onTerminate" :disabled="disabled">终止跟进</el-button>
			</div>
			<yi-table table-height="100%" :columns="columns" :data="tableData">
				<template #operation="{ row }">
					<span class="click" style="color: #f56c6c" @click="onDelete(row)">删除</span>
					<span class="click" style="margin-left: 8px" @click="onEdit(row)">编辑</span>
				</template>
			</yi-table>
		</div>
	</el-dialog>
</template>

<script setup>
import { defineExpose, inject, defineEmits } from 'vue'
import { useState, useModal, useFetch } from '@/utils/hooks.js'
import {
	followUpList,
	submitFollowUp,
	editFollowUp,
	deleteFollowUp,
	terminateFollowUp,
} from '@/api/recruit'

const $message = inject('$message')
const emit = defineEmits(['on-refresh'])
const { visible, setVisible } = useModal()
const [model, setModel] = useState([])

const [formData, setForm] = useState({})
const [tableData, setTable] = useState([])
const { isLoading, fetchData } = useFetch()
const [disabled, setDisabled] = useState(false)
const columns = [
	{
		prop: 'content',
		label: '跟进内容',
	},
	{
		prop: 'follower',
		label: '跟进人',
		width: 140,
	},
	{
		prop: 'followTime',
		label: '跟进时间',
	},
	{
		prop: 'operation',
		label: '操作',
	},
]
async function open(data) {
	console.log('data', data)
	setVisible(true)
	setDisabled(data.status === 5)
	setModel(data)
	setTable([{}])
	getList()
}

function onClose() {
	emit('on-refresh')
	setVisible(false)
	setForm({})
}
async function getList() {
	const { data } = await fetchData(followUpList, model.value.driverClueId)
	tableData.value = data
}
async function onDelete(row) {
	const { followUpId } = row
	const { err } = await fetchData(deleteFollowUp, followUpId)
	if (err) $message.error(err.message)
	else $message.success('删除成功')
	getList()
}
function onEdit(row) {
	formData.value = { ...row }
}
async function onTerminate() {
	const { content } = formData.value
	const driverClueId = model.value.driverClueId
	const { err } = await fetchData(terminateFollowUp, { content, driverClueId })
	if (err) return $message.error(err.message)
	$message.success('操作成功')
	onClose()
}
async function onSubmit() {
	const { content, followUpId } = formData.value
	if (!content) return $message.error('请填写跟进内容')
	const driverClueId = model.value.driverClueId
	const service = followUpId ? editFollowUp : submitFollowUp
	const payload = { content }
	if (!followUpId) {
		payload.driverClueId = driverClueId
	} else {
		payload.followUpId = followUpId
	}
	const { err } = await fetchData(service, payload)
	if (err) return $message.error(err.message)
	formData.value = {}
	$message.success('提交跟进成功')
	getList()
}
defineExpose({ open })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.click {
	cursor: pointer;
	color: #409eff;
}
.button-list {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin: 20px 0 40px 0;
}
</style>
