<template>
	<div class="container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">报名司机</div>
				<div class="header-button">
					<el-button
						type="primary"
						v-permission="'pc/recruitment-center/driver-clue/assign'"
						@click="onDivide"
					>
						报名分配
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="settingRef" @change="onChangeFilter" />
				<div class="button-list">
					<el-button
						v-for="(button, index) in statusList"
						:key="button.key"
						:class="selectedIndex === index ? 'selected-button' : ''"
						@click="onStatusChange(index, button)"
					>
						{{ button.title }}({{ count[button.key] }})
					</el-button>
				</div>
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columns"
					:data="tableData"
					:params="{ page }"
					autoWidth
					showSelection
					@selection-change="onSelectionChange"
				>
					<template #operation="{ row }">
						<span
							class="click"
							@click="onFollow(row)"
							v-if="row.status != 1"
							v-permission="'pc/recruitment-center/driver-clue/followUp'"
						>
							跟进
						</span>
						<span
							class="click"
							style="margin-left: 8px"
							@click="onLog(row)"
							v-permission="'pc/recruitment-center/driver-clue/log'"
						>
							日志
						</span>
					</template>
					<template #status="{ value }">
						<span>{{ mapDict('driver_clue_status', value) }}</span>
					</template>
					<template #channel="{ value }">
						<span>{{ mapDict('driver_user_channel', value) }}</span>
					</template>
					<template #source="{ value }">
						<span>{{ mapDict('driver_user_source', value) }}</span>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<divide-form ref="divideRef" @on-refresh="fetchList" />
		<follow-form ref="followRef" @on-refresh="fetchList" />
		<follow-log ref="logRef" />
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'RecruitApply',
})
</script>

<script setup name="RecruitApply">
import { inject, ref, onMounted } from 'vue'
import { setting, columns } from './config'
import { useState, useFetch, useDict, useFilter } from '@/utils/hooks.js'
import { applyDriverList, driverStat, applyCityList, followerList } from '@/api/recruit'
import DivideForm from './divide'
import FollowForm from './follow'
import FollowLog from './followLog'

const $message = inject('$message')

const settingRef = ref(setting)
const divideRef = ref(null)
const followRef = ref(null)
const logRef = ref(null)

const statusList = [
	{ title: '全部', key: 'allNum' },
	{ title: '待跟进', key: 'waitFollowNum', status: 2 },
	{ title: '跟进中', key: 'followingUpNum', status: 3 },
	{ title: '已认证', key: 'signedNum', status: 4 },
	{ title: '已失效', key: 'invalidNum', status: 5 },
]

const [params, setParams] = useState({})
const [totalCount, setTotal] = useState(0)
const [tableData, setTable] = useState([])
const { isLoading, fetchData } = useFetch()
const [dict, setDict] = useState({})
const [selection, setSelection] = useState([])
const [status, setStatus] = useState({ status: null })
const [page, setPage] = useState({ current: 1, size: 20 })
const [selectedIndex, setIndex] = useState(0)
const [count, setCount] = useState({})

async function fetchList(refreshStatistics = true) {
	fetchStat()
	const payload = { ...params.value, page: page.value, ...status.value }
	const service = applyDriverList
	const { data, err } = await fetchData(service, payload)
	if (err) {
		$message.error(err.message)
	} else {
		if (refreshStatistics) fetchStat()
		setTable(data.records)
		setTotal(data.total)
	}
}
async function fetchStat() {
	const payload = { ...params.value, page: page.value }
	const { data, err } = await fetchData(driverStat, payload)
	if (err) {
		$message.error(err.message)
	} else {
		setCount(data)
		console.log('统计', data)
	}
}
async function fetchFollower() {
	const fn = () => followerList(`Recruitment?isAll=false`)
	const res = await useFilter(fn, { name: 'realName', code: 'id' })
	const followerId = findItem('followerId')
	console.log('res', res.data)
	followerId.options = res.data
}
function findItem(key) {
	return settingRef.value.filters.find(item => item.attr === key)
}
async function fetchFilter() {
	const { data } = await useFilter(() => applyCityList(20), { name: 'name', code: 'value' })
	const cityCode = findItem('cityCode')
	console.log('data', data)
	cityCode.options = data
}

function onStatusChange(index, button) {
	setIndex(index)
	// eslint-disable-next-line no-prototype-builtins
	if (button.hasOwnProperty('status')) {
		setStatus({ status: button.status })
	} else {
		setStatus({ status: null })
	}
	fetchList()
}

function onSizeChange(size) {
	setPage({ current: 1, size })
	fetchList()
}
function onPageChange(current) {
	const { size } = page.value
	setPage({ current, size })
	fetchList()
}

function onChangeFilter(event) {
	console.log('event', event)
	setParams(event)
	fetchList()
}
function onSelectionChange(val) {
	setSelection(val)
}
async function fetchDict() {
	const dict = await useDict(['driver_clue_status', 'driver_user_channel', 'driver_user_source'])
	setDict(dict)
}
function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}

function onFollow(item) {
	followRef.value.open(item)
}
function onLog(item) {
	logRef.value.open(item)
}
function onDivide() {
	if (!selection.value.length) return $message.warning('请选择司机')
	divideRef.value.open(selection.value)
}
function init() {
	fetchDict()
	fetchList()
	fetchFilter()
	fetchFollower()
}

onMounted(() => {
	init()
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
.click {
	cursor: pointer;
	color: #409eff;
}
.button-list {
	padding: 0 !important;
	margin-top: 10px;
	span {
		margin-left: 5px;
	}
}
.selected-button {
	border: 1px solid #409eff;
	color: #409eff;
}
</style>
