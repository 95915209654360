export const columns = [
	{
		prop: 'city',
		label: '所属城市',
		width: '160px',
	},
	{
		prop: 'mobile',
		label: '手机号',
		width: '160px',
	},
	{
		prop: 'status',
		label: '报名状态',
		width: '160px',
	},
	{
		prop: 'follower',
		label: '跟进人',
		width: '160px',
	},
	{
		prop: 'followContent',
		label: '跟进记录',
		width: '280px',
	},
	{
		prop: 'followTime',
		label: '跟进时间',
		width: '230px',
	},
	{
		prop: 'referrerMobile',
		label: '注册推荐人手机号',
		width: '180px',
	},
	{
		prop: 'contractReferrerMobile',
		label: '合同推荐人手机号',
		width: '180px',
	},
	{
		prop: 'createTime',
		label: '报名时间',
		width: '230px',
	},
	{
		prop: 'signedTime',
		label: '司机认证时间',
		width: '230px',
	},
	{
		prop: 'channel',
		label: '注册渠道',
		width: '160px',
	},
	{
		prop: 'source',
		label: '注册来源',
		width: '160px',
	},
	{
		prop: 'operation',
		label: '操作',
		width: '120px',
		fixed: 'right',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			placeholder: '请输入关键字',
			label: '关键字',
			attr: 'keyword',
			type: 'search',
		},
		{
			placeholder: '请选择跟进人',
			label: '跟进人',
			attr: 'followerId',
			type: 'select',
			options: [],
		},

		{
			placeholder: '请选择报名时间',
			label: '报名时间',
			attr: 'createTime',
			type: 'date',
		},
		{
			placeholder: '请选择所属城市',
			label: '所属城市',
			attr: 'cityCode',
			type: 'select',
			options: [],
		},
		{
			placeholder: '请选择注册来源',
			label: '注册来源',
			attr: 'source',
			type: 'select',
			options: [],
			dictKey: 'driver_user_source',
		},
		{
			placeholder: '请选择注册渠道',
			label: '注册渠道',
			attr: 'channel',
			type: 'select',
			options: [],
			dictKey: 'driver_user_channel',
		},
	],
}

export const form = [
	{
		prop: 'driverNo',
		label: '司机ID',
		component: 'el-input',
		attrs: { placeholder: '请输入司机ID' },
	},
	{
		prop: 'companyId',
		label: '所属公司',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择所属公司' },
	},
	{
		prop: 'name',
		label: '司机姓名',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入司机姓名' },
	},
	{
		prop: 'mobile',
		label: '手机号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入司机手机号' },
	},
	{
		prop: 'idCard',
		label: '身份证号',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入身份证号' },
	},
	{
		prop: 'sex',
		label: '性别',
		required: true,
		component: 'el-select',
		attrs: { placeholder: '请选择性别' },
	},
	{
		prop: 'idCardAddress',
		label: '身份证地址',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入身份证地址' },
	},
	{
		prop: 'birthday',
		label: '出生日期',
		required: true,
		custom: true,
	},
	{
		prop: 'presentAddress',
		label: '现居住地址',
		required: true,
		component: 'el-input',
		attrs: { placeholder: '请输入现居住地址' },
	},
	{
		prop: 'contactPersonName',
		label: '紧急联系人',
		component: 'el-select',
		options: [],
	},
	{
		prop: 'contactPersonMobile',
		label: '联系人手机',
		component: 'el-input',
		attrs: { placeholder: '请输入联系人手机' },
	},
	{
		prop: 'drivingLicenseDate',
		label: '驾驶证日期',
		custom: true,
	},
]

export const uploadForm = [
	{
		prop: 'idCardPic',
		required: true,
		label: '身份证',
		custom: true,
	},
	{
		prop: 'drivingLicensePic',
		label: '驾驶证',
		component: 'yi-file-uploader',
		required: true,
		cWidth: 500,
		attrs: {
			accept: '.jpg,.jpeg,.png,.bmp',
			max: 5,
			message: '请上传驾驶证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
	{
		prop: 'certificateProfessionalPic',
		label: '从业资格证',
		component: 'yi-file-uploader',
		cWidth: 500,
		attrs: {
			accept: '.jpg,.jpeg,.png,.bmp',
			max: 5,
			message: '请上传从业资格证',
			productType: 'renrenjia',
			sourceType: 'image',
			uploaderSize: 20,
		},
	},
]
