<template>
	<el-dialog
		v-model="visible"
		width="600px"
		class="container"
		title="分配司机报名跟进人"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading">
			<p>
				已选中
				<span style="color: red; font-size: 14px; font-weight: 500">{{ model.length }}</span>
				名司机，司机明细如下：
			</p>
			<p>{{ drivers }}</p>
			<p>确定批量更新以上报名的跟进人</p>
			<el-form :model="formData" :rules="rules" ref="formRef">
				<el-form-item label="城市" prop="cityCode" label-width="80">
					<el-select v-model="formData.cityCode" @change="onCityChange">
						<el-option
							v-for="item in cities"
							:key="item.value"
							:label="item.name"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="跟进人" prop="followerId" label-width="80">
					<el-select v-model="formData.followerId">
						<el-option
							v-for="item in maintainer"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						></el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div>

		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="onSubmit">确定</el-button>
				<el-button @click="onClose">取消</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { defineExpose, inject, defineEmits, ref } from 'vue'
import { useState, useModal, useFetch, useSelect } from '@/utils/hooks.js'
import { applyCityList, followerList, assignDriver } from '@/api/recruit.js'

const $message = inject('$message')
const emit = defineEmits(['on-refresh'])
const { visible, setVisible } = useModal()
const [model, setModel] = useState([])
const [maintainer, setMaintainer] = useState([])
const [cities, setCities] = useState([])
const [drivers, setDrivers] = useState('')
const [formData, setForm] = useState({})

const { isLoading, fetchData } = useFetch()

const formRef = ref(null)
const rules = {
	followerId: [{ required: true, message: '请选择跟进人', trigger: 'blur' }],
	cityCode: [{ required: true, message: '请选择城市', trigger: 'blur' }],
}

async function open(data) {
	setVisible(true)
	setModel(data)
	console.log('data', data)
	const str = data
		.map(item => `${item.mobile}` + `${item.name ? '(' + item.name + ')' : ''}`)
		.join('、')
	setDrivers(str)
	fetchList()
}

async function fetchList() {
	const { all } = await useSelect(() => applyCityList(20))
	if (all) {
		const same = model.value.every(item => item.cityCode == model.value[0].cityCode)
		if (same) {
			const exist = all.find(item => item.value * 1 == model.value[0].cityCode * 1)
			if (exist) {
				formData.value.cityCode = exist.value
				fetchFollower()
			}
		}

		setCities(all)
	}
}
function onCityChange() {
	fetchFollower()
}
async function fetchFollower() {
	const fn = () => followerList(`Recruitment?isAll=false`, [formData.value.cityCode])
	const res = await useSelect(fn, { name: 'realName', code: 'id' })
	console.log('res', res)
	setMaintainer(res.data)
}

function onClose() {
	setVisible(false)
	setForm({})
}

async function onSubmit() {
	await formRef.value.validate()
	const { cityCode, followerId } = formData.value
	const driverClueIds = model.value.map(item => item.driverClueId)
	const { err } = await fetchData(assignDriver, {
		driverClueIds,
		cityCode,
		followerId,
	})
	if (err) return $message.error(err.message)
	$message.success('分配跟进人成功')
	emit('on-refresh')
	onClose()
}
defineExpose({ open })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
</style>
