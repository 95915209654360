<template>
	<el-dialog
		v-model="visible"
		width="820px"
		class="container"
		title="司机报名记录操作日志"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div v-loading="isLoading">
			<yi-table table-height="100%" :columns="columns" :data="tableData"></yi-table>
		</div>
	</el-dialog>
</template>

<script setup>
import { defineExpose, inject } from 'vue'
import { useState, useModal, useFetch } from '@/utils/hooks.js'
import { operationRecords } from '@/api/vehicle'

const $message = inject('$message')

const { visible, setVisible } = useModal()

const [tableData, setTable] = useState([])
const { isLoading, fetchData } = useFetch()
const columns = [
	{
		prop: 'createByName',
		label: '修改人',
		width: 120,
	},
	{
		prop: 'createTime',
		label: '修改时间',
	},
	{
		prop: 'content',
		label: '修改内容',
		width: 380,
	},
]
async function open(data) {
	setVisible(true)
	const { driverClueId } = data
	getList(driverClueId)
}

function onClose() {
	setVisible(false)
}
async function getList(id) {
	const { data, err } = await fetchData(operationRecords, {
		logKey: id,
		page: { current: 1, size: 99 },
	})
	if (err) return $message.error(err.message)
	const logData = data.records.map(item => JSON.parse(item.logContent))
	setTable(logData)
}
defineExpose({ open })
</script>

<style lang="less" scoped>
.title {
	border-left: 3px solid #2e3443;
	color: #2e3443;
	padding-left: 4px;
	font-size: 16px;
	font-weight: 500;
}
.click {
	cursor: pointer;
	color: #409eff;
}
.button-list {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin: 20px 0 40px 0;
}
</style>
